import React from 'react';
import { Hero } from '../Hero/Hero';
import Room from '../Room/Room';
import AboutSquar from '../AboutSquar/AboutSquar';
import SliderComponent from '../SliderComponent/SliderComponent';
import ReviewSlider from '../SliderComponent/ReviewSlider/ReviewSlider';
import { ContactUs } from '../ContactUs/ContactUs';
import './Home.css';

const Home = () => {
  return (
    <div>
      <Hero id="landing-page" />
      <Room />
      <AboutSquar id="about-us" />
      <SliderComponent />
      <ReviewSlider />
      <ContactUs id="contact-us" />
    </div>
  );
};

export default Home;
