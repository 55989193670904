import React, { useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import './App.css';
import Navbar from './Components/Navbar/Navbar';
import Home from './Components/Home/Home';
import Footer from './Components/Footer/Footer';
import AOS from 'aos';
import 'aos/dist/aos.css';
import ThankYou from './Components/ThankYou/ThankYou';
import Admin from './Components/Booking/Admin';

function App() {
  useEffect(() => {
    AOS.init({
      duration: 2000,
      easing: 'ease',
    });
    AOS.refresh();
  }, []);

  return (
    <div className="App">
      <Navbar />
      
      <Routes>
      <Route path="/" element={<Home/>} />
      
        <Route path="/thankyou" element={<ThankYou />} />
        <Route path="/admin" element={<Admin/>} />


      </Routes>
      < Footer />
    </div>
  );
}

export default App;
