import React from 'react';
import './Footer.css';
import { FaFacebookF, FaInstagram } from 'react-icons/fa';

function Footer() {
  const scrollToSection = (sectionId) => {
    const element = document.getElementById(sectionId);
    if (element) {
      const header = document.querySelector('.navbar');
      const headerHeight = header ? header.offsetHeight : 0;
      const elementPosition = element.getBoundingClientRect().top;
      const offsetPosition = elementPosition + window.pageYOffset - headerHeight;

      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth'
      });
    }
  };

  const handleBookNowClick = () => {
    scrollToSection('landing-page'); // Adjust to the section you want to scroll to
  };

  return (
    <footer className="footer">
      <div className="footer__container">
        <div className="footer-contact">
          <h2>Useful Links</h2>
          <p onClick={() => scrollToSection('landing-page')} className="footer-link">Home</p>
          <p onClick={() => scrollToSection('about-us')} className="footer-link">About</p>
          <p onClick={() => scrollToSection('contact-us')} className="footer-link">Contact</p>
        </div>
      
        <div className="footer-contact">
          <h2>CONTACT US</h2>
          <p>GairiKhet Margh, Pokhara 33700, Nepal</p>
          <p>+9779745992634</p>
          <p>info@asquarenest.com</p>
        </div>
        
        <div className="footer-contact">
          <h2>Follow Us On</h2>
          <div className="footer__social-links">
            <a href="https://www.facebook.com/profile.php?id=61559501471841" target="_blank" rel="noopener noreferrer">
              <FaFacebookF />
            </a>
            <a href="https://instagram.com" target="_blank" rel="noopener noreferrer">
              <FaInstagram />
            </a>
          </div>
          <button className="footer__button" onClick={handleBookNowClick}>Book Now</button>
        </div>
        
        <div className="footer_img">
          <img src='img/Untitled design (88).png' alt='' />
        </div>
      </div>
    </footer>
  );
}

export default Footer;
