import React from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './ReviewSlider.css'; // Ensure you have the corresponding CSS file

const ReviewSlider = () => {
  const settings = {
    infinite: true,
    speed: 300,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnHover: true,
    swipe: true,
    nextArrow: null,
    prevArrow: null,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          centerMode: true,
          centerPadding: '30px',
        }
      }
    ]
  };

  return (
    <div  className="review-slider-container">
      <h2 style={{color:"black"}}>Here are some Reviews of Our Clients</h2>
      <iframe src='https://widgets.sociablekit.com/google-reviews/iframe/25440977' frameborder='0' width='100%' height='1000'></iframe>
    </div>
  );
};

export default ReviewSlider;
