import React from 'react'
import "./AboutSquar.css"
const AboutSquar = () => {
  return (
    <div id="about-us" className='mainabouts'>
       
<div  className='abouts'>
<span data-aos="zoom-in" className='textabout'> 
<h3> Welcome to A Square Nest – Your Perfect Stay </h3>
<p> Welcome to A Square Nest,located at Pokhara Lakeside Jarbaer
Thadopasal Gahiri Khet Marg. where luxury meets tranquility. Nestled
in the heart of pristine nature, our hotel offers unparalleled comfort
and world-class amenities. Each room is designed with elegance and
equipped with modern conveniences, ensuring a restful stay. Enjoy
gourmet dining at our on-site restaurant, or explore scenic trails
nearby. Whether you're here for a romantic getaway, a family vacation,
or a business retreat, A Square Nest promises an unforgettable
experience. Your dream escape awaits at A Square Nest . <br/>
At A Square Nest, we focus on excellent service. Our friendly staff is
here to make your stay special, providing personalized help and
concierge services. Our prime location means you're close to major
attractions, shopping areas, and entertainment spots. <br/>
Experience the perfect blend of luxury, comfort, and convenience at A
Square Nest – where every stay is a remarkable journey. Book your
stay with us today and embark on an unforgettable experience.
</p>
</span>
<span data-aos="fade-top" className='aboutimg'> 
<img src='img/about/SAVE_20240621_195228 (1).jpg' alt=' '/>




</span>
</div>

    </div>
  )
}

export default AboutSquar