import React, { useState } from 'react';
import { FaUser, FaEnvelope, FaPhone } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { textdb } from '../../firebaseConfig'; // Import the correct Firestore instance
import { collection, addDoc } from 'firebase/firestore';
import './BookingForm.css';

const BookingForm = () => {
  const [formData, setFormData] = useState({
    fullName: '',
    email: '',
    mobileNumber: ''
  });

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [message, setMessage] = useState('');
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setMessage('');

    try {
      // Add the booking data to the 'bookings' collection in Firestore
      await addDoc(collection(textdb, 'bookings'), {
        ...formData,
        timestamp: new Date(), // Add a timestamp to each booking
      });
      
      // Redirect to the thank you page after successful submission
      navigate('/thankyou');
    } catch (error) {
      setMessage(`Booking failed: ${error.message}`);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="booking-form-container">
      <form className="booking-form" onSubmit={handleSubmit}>
        <h2 className="form-title">Book Your Stay</h2>
        <div className="form-group">
          <FaUser className="form-icon" />
          <input
            type="text"
            name="fullName"
            value={formData.fullName}
            onChange={handleChange}
            required
            placeholder="Full Name"
          />
        </div>
        <div className="form-group">
          <FaEnvelope className="form-icon" />
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            required
            placeholder="Email Address"
          />
        </div>
        <div className="form-group">
          <FaPhone className="form-icon" />
          <input
            type="tel"
            name="mobileNumber"
            value={formData.mobileNumber}
            onChange={handleChange}
            required
            placeholder="Mobile Number"
          />
        </div>
        <button type="submit" className="custom-submit-button" disabled={isSubmitting}>
          {isSubmitting ? 'Submitting...' : 'Book Now'}
        </button>
        {message && <p className="error-message">{message}</p>}
      </form>
    </div>
  );
};

export default BookingForm;
