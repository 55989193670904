import React, { useState, useEffect } from 'react';
import { collection, getDocs, query, orderBy, deleteDoc, doc } from 'firebase/firestore';
import { textdb } from '../../firebaseConfig'; // Import Firestore instance
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';
import { useNavigate } from 'react-router-dom';
import './Admin.css';

const Admin = () => {
  const [bookings, setBookings] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filter, setFilter] = useState('newest');
  const [authenticated, setAuthenticated] = useState(false); // For custom authentication
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  // Hardcoded credentials (these should be kept secure and not hardcoded in a real application)
  const adminCredentials = {
    email: 'asquarenest@gmail.com',
    password: '445566',
  };

  useEffect(() => {
    const storedAuth = localStorage.getItem('authenticated');
    if (storedAuth) {
      setAuthenticated(true);
    }

    const fetchBookings = async () => {
      setLoading(true);
      try {
        let q;
        if (filter === 'newest') {
          q = query(collection(textdb, 'bookings'), orderBy('timestamp', 'desc'));
        } else {
          q = query(collection(textdb, 'bookings'), orderBy('timestamp', 'asc'));
        }

        const querySnapshot = await getDocs(q);
        const bookingData = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        setBookings(bookingData);
      } catch (error) {
        console.error('Error fetching bookings: ', error);
      } finally {
        setLoading(false);
      }
    };

    if (authenticated) {
      fetchBookings();
    }
  }, [filter, authenticated]);

  const handleFilterChange = (e) => {
    setFilter(e.target.value);
  };

  const exportToExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(bookings);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Bookings');

    const excelBuffer = XLSX.write(workbook, {
      bookType: 'xlsx',
      type: 'array',
    });
    const data = new Blob([excelBuffer], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });
    saveAs(data, 'bookings.xlsx');
  };

  const exportToCSV = () => {
    const worksheet = XLSX.utils.json_to_sheet(bookings);
    const csvBuffer = XLSX.utils.sheet_to_csv(worksheet);
    const data = new Blob([csvBuffer], {
      type: 'text/csv;charset=utf-8;',
    });
    saveAs(data, 'bookings.csv');
  };

  const deleteAllBookings = async () => {
    if (
      window.confirm(
        'Make sure you download the data. After deletion, there will be no backup. Are you sure you want to delete all bookings?'
      )
    ) {
      const querySnapshot = await getDocs(collection(textdb, 'bookings'));
      querySnapshot.forEach(async (docSnapshot) => {
        await deleteDoc(doc(textdb, 'bookings', docSnapshot.id));
      });
      setBookings([]); // Clear the bookings from the state
    }
  };

  const handleLogin = (e) => {
    e.preventDefault();
    if (email === adminCredentials.email && password === adminCredentials.password) {
      setAuthenticated(true);
      setError('');
      localStorage.setItem('authenticated', 'true'); // Save authentication state
    } else {
      setError('Invalid email or password');
    }
  };

  const handleLogout = () => {
    setAuthenticated(false);
    localStorage.removeItem('authenticated');
    setEmail('');
    setPassword('');
    navigate('/admin'); // Navigate to the login page
  };

  if (!authenticated) {
    return (
      <div className="premium-login-container">
  <h2>Admin Login</h2>
  <form onSubmit={handleLogin} className="premium-login-form">
    <div className="premium-login-form-group">
      <label htmlFor="email">Email:</label>
      <input
        type="email"
        id="email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        required
      />
    </div>
    <div className="premium-login-form-group">
      <label htmlFor="password">Password:</label>
      <input
        type="password"
        id="password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        required
      />
    </div>
    {error && <p className="premium-login-error-message">{error}</p>}
    <button type="submit" className="premium-login-button">Login</button>
  </form>
</div>

    );
  }

  if (loading) {
    return <p>Loading bookings...</p>;
  }

  return (

        <div className="admin-container">
          <h2 className="admin-heading">Admin Dashboard</h2>
          <div className="admin-filter-container">
            <label htmlFor="filter">Sort by: </label>
            <select id="filter" value={filter} onChange={handleFilterChange}>
              <option value="newest">Newest to Oldest</option>
              <option value="oldest">Oldest to Newest</option>
            </select>
          </div>
      
          <button onClick={exportToExcel} className="admin-export-button">Download Excel</button>
          <button onClick={exportToCSV} className="admin-export-button">Download CSV</button>
          <button onClick={handleLogout} className="admin-logout-button">Logout</button>
      
          <table className="admin-booking-table">
            <thead>
              <tr>
                <th>Full Name</th>
                <th>Email</th>
                <th>Mobile Number</th>
                <th>Timestamp</th>
              </tr>
            </thead>
            <tbody>
              {bookings.map((booking) => (
                <tr key={booking.id}>
                  <td>{booking.fullName}</td>
                  <td>{booking.email}</td>
                  <td>{booking.mobileNumber}</td>
                  <td>{new Date(booking.timestamp.seconds * 1000).toLocaleString()}</td>
                </tr>
              ))}
            </tbody>
          </table>
          <button onClick={deleteAllBookings} className="admin-delete-button">Delete All Bookings</button>
       <br/>  <a href="https://www.anantainfotech.com" target="_blank"><p8  style={{fontSize:"10px" ,marginTop:"90px"}}>devloped by -anantainfotech</p8></a> 
        </div>
      );
      
};

export default Admin;
