import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import BookingForm from '../Booking/BookingForm';
import './Hero.css';

export const Hero = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Simulate data loading
    fetchData().then(() => setLoading(false));
  }, []);

  const fetchData = async () => {
    // Simulate API call
    await new Promise((resolve) => setTimeout(resolve, 2000)); // Replace with actual API call
    // Update loading state when data is ready
  };

  return (
    <div id="landing-page" className='hero'>
      <>
        <div data-aos="zoom-in"
             data-aos-offset="100"
             data-aos-easing="ease-in-sine"   className='herotxt'>
          <h2> Experience <span >Luxury</span> and Comfort at Our Premier Hotel - Your Perfect
          Getaway Awaits!</h2>
          {/* <p> We are Focused our Client With the heighst level of quality and exelent customer support</p> */}
          <span className='button-conatainer'>
          </span> 
        </div>
        <div data-aos="zoom-in"
             data-aos-offset="100"
             data-aos-easing="ease-in-sine" className='herodata'>
          <BookingForm />
        </div>
      </>
    </div>
  );
}
