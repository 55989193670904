import React, { useState, useRef, useEffect } from 'react';
import { FaBars, FaTimes } from 'react-icons/fa';
import './Navbar.css';

const Navbar = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const menuRef = useRef(null);
  const [showImage, setShowImage] = useState(false);

  const scrollToSection = (sectionId) => {
    const element = document.getElementById(sectionId);
    if (element) {
      const headerOffset = document.querySelector('.navbar').offsetHeight;
      const elementPosition = element.getBoundingClientRect().top;
      const offsetPosition = elementPosition - headerOffset;
  
      window.scrollTo({
        top: window.pageYOffset + offsetPosition,
        behavior: 'smooth'
      });
    }
  };
  

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const closeMenu = () => {
    setMenuOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setMenuOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      setShowImage(window.scrollY >= 10);
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <>
      <nav className={showImage ? "navbar active" : "navbar"}>
        <div className="navbar-container">
          <a href="/">
            <span className='navbar-logo'>
              <img src='img/Logo/SQUARE nest png-01-01.png' alt='logo' />
            </span>
          </a>
          <div className={`menu-icon ${menuOpen ? 'open' : ''}`} onClick={toggleMenu}>
            {menuOpen ? <FaTimes /> : <FaBars />}
          </div>
          <ul ref={menuRef} className={`nav-menu ${menuOpen ? 'active' : ''}`}>
            <li className="nav-item">
              <a className="nav-link" onClick={() => { scrollToSection('landing-page'); closeMenu(); }}>
                Home
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" onClick={() => { scrollToSection('about-us'); closeMenu(); }}>
                About Us
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" onClick={() => { scrollToSection('contact-us'); closeMenu(); }}>
                Contact Us
              </a>
            </li>
          </ul>
        </div>
      </nav>
    </>
  );
};

export default Navbar;
