import React from 'react'
import "./Room.css"
const Room = () => {
  return (
    <div className='room-conatiner'>
        <div className='room-card'>
            <img data-aos="zoom-in" src='img/c/BG PRODUCTION (6)_11zon.jpg' alt='' />
            <img  data-aos="zoom-in" src='img/c/3O2A0816_11zon.jpg' alt='' />

            <img  data-aos="zoom-in" src='img/c/3O2A0821_11zon.jpg' alt='' />

            <img data-aos="zoom-in" src='img/c/BG PRODUCTION (13)_11zon.jpg' alt='' />

        </div>
    </div>
  )
}

export default Room