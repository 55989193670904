import React, { useRef } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import LazyLoad from 'react-lazyload';
import './SliderComponent.css'; // Custom CSS for styling

const SliderComponent = () => {
  const sliderRef = useRef(null);

  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: '10px',
    autoplay: true,
    autoplaySpeed: 3000,
    focusOnSelect: true,
    arrows: false,
    pauseOnHover: false, 
  };

  const images = [
    "carusal/3O2A0785_11zon.jpg",
    
    "carusal/BG PRODUCTION (3)_11zon.jpg",
    // "carusal/BG PRODUCTION (4)_11zon.jpg",
    "carusal/BG PRODUCTION (6)_11zon.jpg",
    "carusal/BG PRODUCTION (7)_11zon.jpg",
    // "carusal/BG PRODUCTION (10)_11zon.jpg",
    "carusal/BG PRODUCTION (11)_11zon.jpg",
    "carusal/BG PRODUCTION (12)_11zon.jpg",
    "carusal/BG PRODUCTION (13)_11zon.jpg",
    "carusal/BG PRODUCTION (14)_11zon.jpg",
    "carusal/BG PRODUCTION (15)_11zon.jpg",
    "carusal/BG PRODUCTION (16)_11zon.jpg",
    "carusal/BG PRODUCTION (17)_11zon.jpg",
    "carusal/ROOM 502 (2)_11zon.jpg",
    "carusal/ROOM 502 (3)_11zon.jpg",
    "carusal/ROOM 502 (9)_11zon.jpg",
  ];

  const handleMouseDown = () => {
    if (sliderRef.current) {
      sliderRef.current.slickPause();
    }
  };

  const handleMouseUp = () => {
    if (sliderRef.current) {
      sliderRef.current.slickPlay();
    }
  };

  return (
    <div className="slider-container">
      <h2>Rooms and Suites</h2>
      <p>Our magnificent Rooms and Suites offer a haven of luxury and leisure. Every room is a harmonious blend of sophistication and comfort, designed to cater to your every need. Enjoy plush bedding, state-of-the-art technology, and luxurious bathrooms equipped with premium toiletries.</p>
      <div data-aos="zoom-in" className="slider-container-1">
        <Slider ref={sliderRef} {...settings}>
          {images.map((imgSrc, index) => (
            <div
              className="slide-item"
              key={index}
              onMouseDown={handleMouseDown}
              onMouseUp={handleMouseUp}
              onTouchStart={handleMouseDown}
              onTouchEnd={handleMouseUp}
            >
              <LazyLoad height={200} offset={100}>
                <img src={`img/${imgSrc}`} alt={`Slide ${index + 1}`} />
              </LazyLoad>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default SliderComponent;
