import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getAuth } from "firebase/auth";
import { getDatabase } from "firebase/database";

// Firebase configuration for your project
const firebaseConfig = {
  apiKey: "AIzaSyD-SfIK5tJ_zNw2DOjcsxaub2uMf3ykIJc",
  authDomain: "asquernest.firebaseapp.com",
  projectId: "asquernest",
  storageBucket: "asquernest.appspot.com",
  messagingSenderId: "1070250551476",
  appId: "1:1070250551476:web:7f9bb0150232275388cfe1",
  measurementId: "G-7KP1P4PPZM",
  databaseURL: "https://asquernest-default-rtdb.firebaseio.com" // If you use Realtime Database
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase services
const textdb = getFirestore(app);      // Firestore for structured data
const storage = getStorage(app);       // Storage for files
const auth = getAuth(app);             // Authentication for users
const database = getDatabase(app);     // Realtime Database for real-time data

// Optional: Disable app verification for testing purposes
auth.settings.appVerificationDisabledForTesting = true;

// Export the initialized services for use in other parts of your application
export { textdb, storage, auth, app, database };
export default firebaseConfig;
