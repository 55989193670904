import React from 'react';
import Navbar from '../Navbar/Navbar';
import { FaFacebookF, FaTwitter, FaInstagram } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import "./ThankYou.css"
const ThankYou = () => {
  
  return (
    <div  className="thank-you-container">
      
      <h1>Thank You!</h1>
      <p>Your booking has been received. We will contact you soon.</p>

      <div style={{display:"flex" , justifyContent:"center" ,color:"white" }} className="footer__social-links">
            <a href="https://www.facebook.com/profile.php?id=61559501471841" target="_blank" rel="noopener noreferrer">
              <FaFacebookF />
            </a>
           
            <a href="https://instagram.com" target="_blank" rel="noopener noreferrer">
              <FaInstagram />
            </a>
            <br/>
          </div>
           <Link to="/"> <button className="footer__button" >Home</button> </Link>
        </div>
   
  );
};

export default ThankYou;
