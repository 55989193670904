import React, { useState, useEffect } from 'react';
import { collection, addDoc, getDocs } from 'firebase/firestore';
import { textdb } from '../../firebaseConfig'; 
import "./ContactUs.css"
import { toast } from 'react-toastify';
import { FaFacebookF } from "react-icons/fa";
import { IoShareSocialSharp } from "react-icons/io5";
import { CiLinkedin } from "react-icons/ci";

export const ContactUs = () => {
 
  const [quoteDetails, setQuoteDetails] = useState({
    name: '',
    email: '',
    phoneNumber: '',
    message: '',
  });
  const [quoteSubmitted, setQuoteSubmitted] = useState(false);
  const [quotes, setQuotes] = useState([]);
  const [uploading, setUploading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setQuoteDetails({ ...quoteDetails, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setUploading(true);
    try {
      const docRef = await addDoc(collection(textdb, 'quotes'), quoteDetails);
      console.log('Document written with ID: ', docRef.id);
      setQuoteSubmitted(true);
      setQuoteDetails({
        name: '',
        email: '',
        phoneNumber: '',
        message: '',
      });

    } catch (error) {
      console.error('Error adding document: ', error);
    }
    window.location.reload();
    toast.success("Success")
  };

  const getQuotesFromFirebase = async () => {
    const querySnapshot = await getDocs(collection(textdb, 'quotes'));
    const quotesData = [];
    querySnapshot.forEach((doc) => {
      quotesData.push({ id: doc.id, ...doc.data() });
    });
    setQuotes(quotesData);
  };

  // Fetch quotes on component mount
  useEffect(() => {
    getQuotesFromFirebase();
  }, []);

  return (
    <div id="contact-us" className='main-contact'>


    
      <div className='contact-map'>

        <iframe



          width="100%"
          height="650px"
          loading="lazy"
          allowfullscreen
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d760.8034846998715!2d83.96752249953295!3d28.207754476235905!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x438dd0b9b7779eb9%3A0xe3daefcdf02fc820!2sA%20Square%20Nest!5e0!3m2!1sen!2sin!4v1723035878690!5m2!1sen!2sin"
          className='map-contact'
        ></iframe>
      </div>
    </div>
  );
};
